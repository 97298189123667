<template>
  <drawer :dismissRoute="dismissRoute">
    <div class="request-builder-container" :ref="'reqBody'">
      <div v-if="isMultiRequest && this.multiRequestData.length > 1" class="multi-requets-tabs">
        <div v-for="(req, index) in multiRequestData" :class="{ activeTabTitle: req.isActive }" :key="index">
          <div @click="selectTab(index)" class="multi-event-tab" :id="index">
            <custome-checkbox class="preference" :title="req.title" :disable="true" />
            <div v-if="index !== 0" class="cancel-req" @click="cancelMultiRequest(index)">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 1L1 11M1 1L11 11" stroke="#EB5B13" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      
      <div>
        <div class="drawer-header">
          <div class="drawer-header-left-part">
            <div class="drawer-title">{{ requestTitle }}</div>
            <div class="drawer-sub-title">Tell us more about your event!</div>
          </div>
          <div class="drawer-close" @click="onCancel">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 6L6 18M6 6L18 18" stroke="#1F1F18" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </div>
        </div>
        <div class="drawer-step-description">
          <step-number step="1" />
          <div class="drawer-step-description-text">
            <div class="drawer-step-description-text-title">Event</div>
            <div class="drawer-step-description-text-details">Everything about the event.</div>
          </div>
        </div>

        <div v-if="!isMultiRequest">
          <el-autocomplete class="new-request__search" v-if="isStaff && !customerId && !$route.params.id"
            v-model="currentCustomer" :fetch-suggestions="searchCustomer" prefix-icon="el-icon-search"
            placeholder="Please, input customer name" @select="handleSearch"></el-autocomplete>
          <el-form label-position="left" ref="requestForm" v-loading="loadingForm" :model="request" :rules="rules">
            <el-form-item label="Event name" prop="name">
              <el-input v-model="request.name" placeholder="Annual board meeting"></el-input>
            </el-form-item><!-- PEOPLE-->
            <div class="people-budget-info">
              <el-form-item label="Number of people" prop="people">
                <!-- <el-input-number v-model="request.people" :min="1"></el-input-number> -->
                <numeric-input @updateValue="request.people = $event" :initialValue="request.people">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.6663 14V12.6667C14.6663 11.4241 13.8165 10.38 12.6663 10.084M10.333 2.19384C11.3103 2.58943 11.9997 3.54754 11.9997 4.66667C11.9997 5.78579 11.3103 6.7439 10.333 7.13949M11.333 14C11.333 12.7575 11.333 12.1362 11.13 11.6462C10.8594 10.9928 10.3402 10.4736 9.68683 10.203C9.19677 10 8.57552 10 7.33301 10H5.33301C4.0905 10 3.46924 10 2.97919 10.203C2.32578 10.4736 1.80665 10.9928 1.536 11.6462C1.33301 12.1362 1.33301 12.7575 1.33301 14M8.99967 4.66667C8.99967 6.13943 7.80577 7.33333 6.33301 7.33333C4.86025 7.33333 3.66634 6.13943 3.66634 4.66667C3.66634 3.19391 4.86025 2 6.33301 2C7.80577 2 8.99967 3.19391 8.99967 4.66667Z"
                      stroke="#4C4C46" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </numeric-input>
              </el-form-item><!-- BUDGET-->
              <el-form-item label="Budget per person" prop="budget">
                <!-- <el-input v-model="request.budget"><template slot="prepend">$</template></el-input> -->
                <numeric-input @updateValue="request.budget = $event" :initialValue="request.budget" :decimal="true">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4 10.6667C4 12.1395 5.19391 13.3334 6.66667 13.3334H9.33333C10.8061 13.3334 12 12.1395 12 10.6667C12 9.19395 10.8061 8.00004 9.33333 8.00004H6.66667C5.19391 8.00004 4 6.80613 4 5.33337C4 3.86061 5.19391 2.66671 6.66667 2.66671H9.33333C10.8061 2.66671 12 3.86061 12 5.33337M8 1.33337V14.6667"
                      stroke="#4C4C46" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </numeric-input>
              </el-form-item>
            </div>
            <!-- CUISINES-->
            <el-form-item label="Preferred cuisines" prop="cuisine_ids">
              <div class="user-select">
                <el-select class="full-width-select" v-model="request.cuisine_ids" multiple=""
                  placeholder="Select up to 3" :multipleLimit="3">
                  <el-option class="user-select" v-for="item in cuisines" :key="item.id" :label="item.name"
                    :value="item.id"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <!-- ADDRESS-->
            <div class="drawer-step-description address-step">
              <step-number step="2" />
              <div class="drawer-step-description-text">
                <div class="drawer-step-description-text-title">Delivery</div>
                <div class="drawer-step-description-text-details">Everything about delivery.</div>
              </div>
            </div>
            <div :class="!(request.address_id && !creatingAddress) ? '' : 'address-container'">
              <el-form-item label="Address" prop="address_id">
                <div class="user-select">
                  <el-select v-model="request.address_id" filterable="filterable" placeholder="Select an option"
                    class="full-width-select">
                    <el-option class="user-select" v-for="item in addresses.filter(addr => addr.customer_id === (customer ? customer.id : null))" :key="item.id" :value="item.id"
                      :label="item.name || item.address_line_1"><span>{{ item.name || item.address_line_1 }}</span>
                      <el-tooltip :content="fullAddress(item)" placement="left">&nbsp;<i
                          class="mat-icon secondary">info</i></el-tooltip>
                    </el-option>
                    <el-option class="select-special-action user-select" label="Create new address"
                      value="new"></el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item label="Suite" v-if="request.address_id && !creatingAddress" prop="suite"
                style="width: 214px">
                <el-input v-model="request.suite" placeholder="suite">{}</el-input>
              </el-form-item>
            </div>
            <section-separator v-show="creatingAddress" label="New address information">
              <address-builder ref="addressForm" has-name="has-name" :forUser="true" label-position="right"
                :customerId="request.customer_id"></address-builder>
            </section-separator><!-- DATE-->
            <div class="address-container">
              <el-form-item label="Date" prop="delivery_date">
                <el-date-picker :popper-class="getRole() === 'user' ? 'user-request-date' : ''"
                  :value="request.delivery_date" placeholder="Select a date" :pickerOptions="datePickerOptions"
                  value-format="yyyy-MM-dd" @input="changeDeliveryDate"></el-date-picker>
              </el-form-item><!-- TIME-->
              <el-form-item label="Time" prop="delivery_time">
                <el-time-picker :popper-class="getRole() === 'user' ? 'user-request-time' : ''" type="date"
                  placeholder="Select a time" v-model="request.delivery_time" :picker-options="timePickerOptions"
                  format="hh:mm A" value-format="HH:mm"></el-time-picker>
              </el-form-item><!-- CONTACT-->
            </div>
            <el-form-item label="Contact" prop="contact_id">
              <div class="user-select">
                <el-select class="full-width-select" v-model="request.contact_id" placeholder="Select an option">
                  <el-option class="user-select-option" v-for="item in contacts.filter(contact => contact.customer_id === (customer ? customer.id : null))" :key="item.id"
                    :label="item.first_name + ' ' + item.last_name" :value="item.id"></el-option>
                  <el-option class="select-special-action user-select-option" label="Create new contact"
                    value="new"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <section-separator v-show="creatingContact" label="New contact information">
              <contact-builder ref="contactForm" :customerId="null" label-position="right"
                :forUser="true"></contact-builder>
            </section-separator><!-- NOTES-->
            <el-form-item label="Custom customer PO" prop="customerPO">
              <el-input v-model="order.customer_po"></el-input>
            </el-form-item>
            <el-form-item label="Comment" prop="notes">
              <el-input type="textarea" v-model="request.notes" max="250"
                :autosize="{ minRows: 2, maxRows: 7 }"></el-input>
            </el-form-item>
            <el-collapse style="margin-top: 3rem">
              <el-collapse-item name="preferences"><template slot="title">
                  <el-form-item label="Preferences"><span class="collapse-item__tip user-collapse">Click to
                      expand</span></el-form-item>
                </template>
                <el-form-item prop="preferences">
                  <user-preserences-builder :newRequest="false" :initialValue="initialPreferencesValue"
                    ref="preferencesForm" :is-request="true"></user-preserences-builder>
                  <!-- <preferences-builder :initialValue="preferences" ref="preferencesForm" :is-request="true"></preferences-builder> -->
                </el-form-item>
              </el-collapse-item>
            </el-collapse>
            <el-form-item class="buttons-section">
              <div class="buttons-section">
                <cancel-button title="Next request" class="add-request-btn" @onClick="addMultiRequest()">
                  Next request
                </cancel-button>
                <cancel-button class="cancel-btn" @onClick="onCancel" title="Cancel"></cancel-button>
                <default-button title="Send Request" @onClick="onSubmit">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.66699 8H13.3337M13.3337 8L9.33366 4M13.3337 8L9.33366 12" stroke="white"
                      stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </default-button>
              </div>
            </el-form-item>
          </el-form>
        </div>

        <div v-else>
          <div v-for="(event, index) in multiRequestData" :key="index">
            <div v-show="event.isActive">
              <el-form label-position="left" :ref="'requestForm' + index" v-loading="loadingForm" :model="event.data"
                :rules="rules">
                <el-form-item label="Event name" prop="name">
                  <el-input v-model="event.data.name" placeholder="Annual board meeting" @input="event.title = $event"
                    :class="event.data.name || validationError ? '' : 'requered-fild'"></el-input>
                  {{ validationError }}
                </el-form-item><!-- PEOPLE-->
                <div class="people-budget-info">
                  <el-form-item label="Number of people" prop="people">
                    <!-- <el-input-number v-model="request.people" :min="1"></el-input-number> -->
                    <numeric-input @updateValue="event.data.people = $event" :initialValue="event.data.people"
                      :inheritanceVal="true">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14.6663 14V12.6667C14.6663 11.4241 13.8165 10.38 12.6663 10.084M10.333 2.19384C11.3103 2.58943 11.9997 3.54754 11.9997 4.66667C11.9997 5.78579 11.3103 6.7439 10.333 7.13949M11.333 14C11.333 12.7575 11.333 12.1362 11.13 11.6462C10.8594 10.9928 10.3402 10.4736 9.68683 10.203C9.19677 10 8.57552 10 7.33301 10H5.33301C4.0905 10 3.46924 10 2.97919 10.203C2.32578 10.4736 1.80665 10.9928 1.536 11.6462C1.33301 12.1362 1.33301 12.7575 1.33301 14M8.99967 4.66667C8.99967 6.13943 7.80577 7.33333 6.33301 7.33333C4.86025 7.33333 3.66634 6.13943 3.66634 4.66667C3.66634 3.19391 4.86025 2 6.33301 2C7.80577 2 8.99967 3.19391 8.99967 4.66667Z"
                          stroke="#4C4C46" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </numeric-input>
                  </el-form-item><!-- BUDGET-->
                  <el-form-item label="Budget per person" prop="budget">
                    <!-- <el-input v-model="request.budget"><template slot="prepend">$</template></el-input> -->
                    <numeric-input @updateValue="event.data.budget = $event" :initialValue="event.data.budget"
                      :decimal="true" :inheritanceVal="true">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M4 10.6667C4 12.1395 5.19391 13.3334 6.66667 13.3334H9.33333C10.8061 13.3334 12 12.1395 12 10.6667C12 9.19395 10.8061 8.00004 9.33333 8.00004H6.66667C5.19391 8.00004 4 6.80613 4 5.33337C4 3.86061 5.19391 2.66671 6.66667 2.66671H9.33333C10.8061 2.66671 12 3.86061 12 5.33337M8 1.33337V14.6667"
                          stroke="#4C4C46" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </numeric-input>
                  </el-form-item>
                </div>
                <!-- CUISINES-->
                <el-form-item label="Preferred cuisines" prop="cuisine_ids">
                  <div class="user-select">
                    <el-select class="full-width-select" v-model="event.data.cuisine_ids" multiple=""
                      placeholder="Select up to 3" :multipleLimit="3">
                      <el-option class="user-select" v-for="item in cuisines" :key="item.id" :label="item.name"
                        :value="item.id"></el-option>
                    </el-select>
                  </div>
                </el-form-item>
                <!-- ADDRESS-->
                <div class="drawer-step-description address-step">
                  <step-number step="2" />
                  <div class="drawer-step-description-text">
                    <div class="drawer-step-description-text-title">Delivery</div>
                    <div class="drawer-step-description-text-details">Everything about delivery.</div>
                  </div>
                </div>
                <div :class="!(event.data.address_id && !creatingAddress) ? '' : 'address-container'">
                  <el-form-item label="Address" prop="address_id">
                    <div class="user-select">
                      <el-select v-model="event.data.address_id" filterable="filterable" placeholder="Select an option"
                        class="full-width-select">
                        <el-option class="user-select" v-for="item in addresses.filter(addr => addr.customer_id === (customer ? customer.id : null))" :key="item.id" :value="item.id"
                          :label="item.name || item.address_line_1"><span>{{ item.name || item.address_line_1 }}</span>
                          <el-tooltip :content="fullAddress(item)" placement="left">&nbsp;<i
                              class="mat-icon secondary">info</i></el-tooltip>
                        </el-option>
                      </el-select>
                    </div>
                  </el-form-item>
                  <el-form-item label="Suite" v-if="event.data.address_id && !creatingAddress" prop="suite"
                    style="width: 214px">
                    <el-input v-model="event.data.suite" placeholder="suite">{}</el-input>
                  </el-form-item>
                </div>
                <section-separator v-show="creatingAddress" label="New address information">
                  <address-builder ref="addressForm" has-name="has-name" :forUser="true" label-position="right"
                    :customerId="event.customer_id"></address-builder>
                </section-separator><!-- DATE-->
                <div class="address-container">
                  <el-form-item label="Date" prop="delivery_date">
                    <el-date-picker :class="event.data.delivery_date || validationError ? '' : 'requered-fild'"
                      :popper-class="getRole() === 'user' ? 'user-request-date' : ''" :value="event.data.delivery_date"
                      v-model="event.data.delivery_date" placeholder="Select a date" :pickerOptions="datePickerOptions"
                      value-format="yyyy-MM-dd"></el-date-picker>
                  </el-form-item><!-- TIME-->
                  <el-form-item label="Time" prop="delivery_time">
                    <el-time-picker :class="event.data.delivery_time || validationError ? '' : 'requered-fild'"
                      :popper-class="getRole() === 'user' ? 'user-request-time' : ''" type="date"
                      placeholder="Select a time" v-model="event.data.delivery_time" :picker-options="timePickerOptions"
                      format="hh:mm A" value-format="HH:mm"></el-time-picker>
                  </el-form-item><!-- CONTACT-->
                </div>
                <el-form-item label="Contact" prop="contact_id">
                  <div class="user-select">
                    <el-select class="full-width-select" v-model="event.data.contact_id" placeholder="Select an option">
                      <el-option class="user-select-option" v-for="item in contacts.filter(contact => contact.customer_id === (customer ? customer.id : null))" :key="item.id"
                        :label="item.first_name + ' ' + item.last_name" :value="item.id"></el-option>
                    </el-select>
                  </div>
                </el-form-item>
                <section-separator v-show="creatingContact" label="New contact information">
                  <contact-builder ref="contactForm" :customerId="null" label-position="right"
                    :forUser="true"></contact-builder>
                </section-separator><!-- NOTES-->
                <el-form-item label="Custom customer PO" prop="customerPO">
                  <el-input v-model="event.data.customer_po"></el-input>
                </el-form-item>
                <el-form-item label="Comment" prop="notes">
                  <el-input type="textarea" v-model="event.data.notes" max="250"
                    :autosize="{ minRows: 2, maxRows: 7 }"></el-input>
                </el-form-item>
                <el-collapse style="margin-top: 3rem">
                  <el-collapse-item name="preferences"><template slot="title">
                      <el-form-item label="Preferences"><span class="collapse-item__tip user-collapse">Click to
                          expand</span></el-form-item>
                    </template>
                    <el-form-item prop="preferences">
                      <user-preserences-builder :newRequest="false" :initialValue="preferences"
                        :ref="'preferencesForm' + index" :is-request="true"></user-preserences-builder>
                      <!-- <preferences-builder :initialValue="preferences" ref="preferencesForm" :is-request="true"></preferences-builder> -->
                    </el-form-item>
                  </el-collapse-item>
                </el-collapse>
                <el-form-item class="buttons-section">
                  <div class="buttons-section">
                    <cancel-button class="add-request-btn" title="Next request" @onClick="addMultiRequest(index)">
                      Next request
                    </cancel-button>
                    <cancel-button class="cancel-btn" @onClick="onCancel" title="Cancel"></cancel-button>
                    <default-button :title="isMultiRequest ? 'Save All' : 'Send Request'"
                      @onClick="isMultiRequest ? submitMultiRerquest(index) : onSubmit">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.66699 8H13.3337M13.3337 8L9.33366 4M13.3337 8L9.33366 12" stroke="white"
                          stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </default-button>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </drawer>
</template>

<script>
import NumericInput from '@/user/blocks/NumericInput.vue';
import RequestBuilder from './RequestBuilder.vue';
import StepNumber from '@/user/blocks/StepNumber.vue';
import DefaultButton from './DefaultButton.vue';
import CancelButton from './CancelButton.vue';
import roleDetection from '@/mixins/roleDetection.js'
import CustomeCheckbox from '@/user/blocks/CustomeCheckbox.vue';
import UserPreserencesBuilder from '@/user/blocks/UserPreserencesBuilder.vue';

const mixins = [RequestBuilder, roleDetection]

function data() {
  return ({
    isMultiRequest: false,
    multiRequestData: [],
    currentForm: '',
    initialPreferences: null,
    validationError: false,
    customer: null,
  })
}

async function getCustomer() {
  await this.$simplecater.get('/account').then((r) => {
    this.customer = r.data.data
  }).catch(() => {
    this.$message.error("Failed to load the user information")
  })
}

function selectTab(index) {
  this.multiRequestData.forEach((tab, tabIndex) => {
    tab.isActive = tabIndex === index;
  });
}

function addMultiRequest(index) {
  if (!this.isMultiRequest) {
    this.skipNestedFormsValidation = false;
    this.$refs.requestForm.validate(valid => {
      if (valid) {
        this.validationError = false
        let promises = [];
        if (this.creatingContact) promises.push(this.createContact());
        if (this.creatingAddress) promises.push(this.createAddress());
        
        // Wait for all promises to complete and ensure we have the updated data
        Promise.all(promises).then(() => {
          // Get the form data AFTER the contact and address have been created and IDs updated
          // This ensures we're using the latest data with the newly created contact_id and address_id
          const requestData = {
            ...this.$refs.requestForm.model,
            contact_id: this.request.contact_id,
            address_id: this.request.address_id,
            suite: this.request.suite
          }
          const preferences = this.$refs.preferencesForm.preferences
          this.initialPreferences = preferences
          
          // Now add the data with the correct IDs to multiRequestData
          this.multiRequestData.push({ title: requestData.name, data: { ...requestData, preferences, customer_po: this.order.customer_po }, isActive: false })
          this.multiRequestData.push({ title: 'New request', data: { ...requestData, delivery_date: null, delivery_time: null, name: null, preferences, customer_po: this.order.customer_po }, isActive: true })
          this.isMultiRequest = true
          document.querySelector('.drawer__content').scrollTop = 0
        }).catch(error => {
          // Handle errors from contact or address creation
          console.error("Error creating contact or address:", error);
          this.$message.error("Failed to create contact or address. Please try again.");
        });
      } else {
        this.$message.error("Some validations failed, please check your inputs")
        this.validationError = true
        return false
      }
    });
  } else {
    const currentForm = this.$refs['requestForm' + (this.multiRequestData.length - 1)]
    currentForm[0].validate(valid => {
      if (valid) {
        this.validationError = false
        const preferences = this.$refs['preferencesForm' + index].preferences
        this.multiRequestData.push({ title: 'New request', data: { ...this.multiRequestData[0].data, delivery_date: null, delivery_time: null, name: '', preferences }, isActive: true })
        this.multiRequestData = this.multiRequestData.map(req => {
          return { ...req, isActive: false }
        })
        this.multiRequestData[this.multiRequestData.length - 1].isActive = true
        document.querySelector('.drawer__content').scrollTop = 0
      } else {
        this.$message.error("Some validations failed, please check your inputs");
        this.validationError = true
        this.multiRequestData = this.multiRequestData.map(req => {
          return { ...req, isActive: false }
        })
        this.multiRequestData[this.multiRequestData.length - 1].isActive = true
        return false;
      }
    })
  }
}

function createContact() {
  return this.$refs.contactForm.create().then(newContact => {
    this.getContacts()
    this.request.contact_id = newContact.id;
  });
}
function createAddress() {
  return this.$refs.addressForm.create().then(newAddress => {
    this.getAddresses()
    this.request.suite = newAddress.address_line_2 ?? this.request.suite;
    this.request.address_id = newAddress.id;
  });
}

function cancelMultiRequest(index) {
  setTimeout(() => {
    this.multiRequestData.forEach(req => req.isActive = false)
    this.multiRequestData[index - 1].isActive = true
    this.multiRequestData.splice(index, 1)
  }, 0)

  //this.multiRequestData.splice(index, 1)
  //this.isMultiRequest = this.multiRequestData.length !== 1
}

function submitMultiRerquest() {
  const currentForm = this.$refs['requestForm' + (this.multiRequestData.length - 1)]
  const refsList = this.$refs

  currentForm[0].validate(valid => {
    if (valid) {
      this.validationError = false
      const pureData = this.multiRequestData.map((item, i) => {
        if (i === 0) {
          const budget_cents = Math.round(item.data.budget * 100);
          return { ...item.data, budget_cents, preferences: this.initialPreferences }
        } else {
          const preferences = refsList['preferencesForm' + i][0].preferences
          const budget_cents = Math.round(item.data.budget * 100);
          return { ...item.data, budget_cents, preferences }
        }
      })
      this.$simplecater.post('/requests/multiple', { requests: pureData })
        .then(() => {
          // SUBMIT SUCCEEDED
          this.$bus.$emit("request-created");
          this.$router.push(this.dismissRoute);
        })
        .catch(() => {
          this.$message.error("It looks like you have an incompleted request.")
          //this.$handleErrorFor("Creating request").bind(this)
        }
          // SUBMIT FAILED
        )
        .finally(() => {
          this.submitting = false;
        });
    } else {
      this.multiRequestData.forEach(req => req.isActive = false)
      this.multiRequestData[this.multiRequestData.length - 1].isActive = true
      this.$message.error("It looks like you have an incompleted request.")
      this.validationError = true
      //this.$message.error("Some validations failed, please check your inputs")
      return false
    }
  })
}

function created() {
  this.getCustomer()
}

const methods = {
  addMultiRequest,
  selectTab,
  submitMultiRerquest,
  cancelMultiRequest,
  createAddress,
  createContact,
  getCustomer
}

const computed = {
  initialPreferencesValue: function () {
    return this.initialPreferences || this.preferences
  }
}

const components = { NumericInput, StepNumber, DefaultButton, CancelButton, CustomeCheckbox, UserPreserencesBuilder }

const mounted = function () {
  this.request.people = 1
}

export default { mixins, components, mounted, data, methods, computed, created };
</script>

<style scoped lang="sass">
.cancel-req
  margin-top: 5px
  cursor: pointer
.new-request__search-cont
  padding: 0 0 30px

.new-request__search
  width: 100%

.drawer-header
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 2.5rem

.drawer-close
  cursor: pointer    

.drawer-title
  color: #1F1F18
  font-size: 1.5rem 
  font-weight: 500
  border: none
  margin-bottom: .5rem
  max-height: 2.5rem

.drawer-sub-title
  font-weight: 500
  font-size: 1rem
  line-height: 1.5rem
  color: #70706B

.collapse-item__tip
  font-style: italic
  opacity: 0.8
  display: inline-block
  float: right
  margin-right: 1rem
  font-weight: 600
  color: $colorPrimary
  &:hover
    opacity: 1

.drawer .el-collapse-item__header, .drawer .el-collapse-item__wrap
  background-color: #FAFAFA

.drawer-step-description
  display: flex
  gap: 1rem
  margin-bottom: 24px
  &-text
    &-title
      color: #1F1F18
      font-size: 1.25rem
      margin-bottom: .25rem
    &-details
      color: #70706B
      font-size: 14px

.people-budget-info
  display: flex
  gap: 1rem

.address-step
  margin-top: 2.5rem

.address-container
  display: flex
  gap: 1rem

.address-container
  display: grid
  grid-template-columns: repeat(2,1fr)

.cancel-btn
  margin-right: 1rem
  cursor: pointer

.buttons-section
  display: flex
  justify-content: flex-end

.request-builder-container
  display: flex
  flex-direction: row

.multi-requets-tabs
  min-width: 17.75rem
  margin-right: 2rem
  padding-right: 2rem
  border-right: 1px solid #EBEBE4
  display: flex
  flex-direction: column
  gap: .625rem

.add-request-btn
  color: #125F43
  margin-right: auto
  cursor: pointer

.add-request-btn:hover
  background: #fff0da

.cancel-request-btn
  color: red
  margin-right: auto
  cursor: pointer  
.multi-event-tab
  padding: .9375rem .625rem
  border-radius: .625rem
  display: flex
  justify-content: space-between

.activeTabTitle
  background-color: #FAFAF5
  border-radius: .625rem
</style>
